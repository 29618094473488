import React, { useState, FC, useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';

import { scheduleMeetingWebhookCall } from '../../services/demo';

const TEST_REQUEST = {
    ehRefId: "",
    messageTimestamp: "2023-02-21T13:00:00Z",
    messageType: "telehealth",
    status: "success",
    ehLang: "en-US", // use en-US is param missing
    ehProject: "FRONTIER",
    clinicianFirstName: "Joe", // Same as above
    clinicianLastName: "Doe",  // Same as above
    clinicianEmail: "test@clincianemail.com", // optional
    clinicianPhone: "+12221112222", // optional
    clinicianTimezone: "TEST_TIMEZONE",
    meetingId: "TEST_MEETING_ID",
    meetingTimestamp: "TEST_MEETING_TIMESTAMP",
    meetingUrl: "TEST_MEETING_URL",
    requestType: "SCHEDULE",
    meetingRequestRawOutput: "{}",
}

const Webhook: FC<{
    ehToken: string;
    ext_ref_id: string | null;
    date: string | undefined;
}> = (props) => {
    const { ehToken, ext_ref_id, date } = props;
    const [spinner, setSpinner] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("");

    const _handleWebhookCall = async () => {
        setStatus("Called the data collection webhook")
        setSpinner(true)

        const data = { ...TEST_REQUEST, ehRefId: ext_ref_id, meetingTimestamp: date }
        const result = await scheduleMeetingWebhookCall(ehToken, data)
        setStatus(result.message)
        setSpinner(false)
    };

    if (!ext_ref_id) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#ffffff' }}>
                <h3>No ext_ref_id provided</h3>
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10 }}>
            {spinner ?
                <CircularProgress />
                :
                <Button style={{ backgroundColor: '#ffffff' }} onClick={_handleWebhookCall}>Send Webhook request</Button>
            }
            {status ? <><h5>Webhook status:</h5><div>{status}</div></> : <></>}
        </div>
    );


}

export default Webhook;