import React, { useState, FC, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from "react-router-dom";
import { CircularProgress, Button, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Webhook from './webhook'
import { ResizableIframe } from './resizableIframe';
import { generateToken } from '../../services/demo';

import logo from '../../assets/logo.png';

const DemoPage: FC<{
    queryParams: URLSearchParams;
}> = (props) => {
    let navigate = useNavigate();
    const { queryParams } = props;
    const [spinner, setSpinner] = useState<boolean>(true);
    const [status, setStatus] = useState<string>("");
    const [loadWidget, setLoadWidget] = useState<boolean>(false);
    const [ehToken, setEhToken] = useState<string>("");
    const [date, setDate] = React.useState<Dayjs | null>(
        dayjs(),
    );
    const searchQueryParams = new URLSearchParams(queryParams);
    const ext_ref_id: string | null = searchQueryParams.has('ehRefId') ? queryParams.get('ehRefId') : null;

    const partner: string | null = process.env.REACT_APP_DEMO_PARTNER ?? null;
    const EH_ACCESS_KEY_ID: string | null = process.env.REACT_APP_EH_ACCESS_KEY_ID ?? null;

    useEffect(() => {
        (async () => {
            setStatus("Checking query params and internal configs")
            if (!partner || !EH_ACCESS_KEY_ID) {
                navigate('/error', { replace: true, state: { status: 'INTERNAL_CONFIG_ERROR', ext_ref_id: '', description: 'Missing internal configs (partner or access_key_id). Please contact EH support.' } });
                return
            }
            if (!ext_ref_id) {
                navigate('/error', { replace: true, state: { status: 'MISSING_REF_ID_IN_DEMO', ext_ref_id: '', description: 'Missing ext_ref_id value in the query parameters.' } });
                return
            }

            setStatus("Now generating a test token")

            const generateTokenResult = await generateToken(partner, EH_ACCESS_KEY_ID, ext_ref_id)
            if (!generateTokenResult.success || !generateTokenResult.token) {
                navigate('/error', { replace: true, state: { status: 'DEMO_TOKEN_CREATION_ERROR', ext_ref_id: '', description: 'Error while generating a token. Please contact the EH support.' } });
                return
            }

            setEhToken(generateTokenResult.token)
            setSpinner(false)
        })()
    }, [partner, EH_ACCESS_KEY_ID, ext_ref_id])

    const _loadWidget = async () => {
        setLoadWidget(!loadWidget)
    };

    const handleDateChange = (newValue: Dayjs | null) => {
        setDate(newValue);
    };

    if (spinner) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h3>{status}</h3>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div style={{
            height: '100vh',
            display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20
        }}>
            <img alt="" src={logo} height="100" />
            <h3>TEST 1: Webhook call</h3>
            <div style={{ textAlign: 'center' }}>
                <ol style={{ paddingLeft: '30px' }}>
                    <li style={{ marginBottom: '10px' }}><h4>If the process works, you will receive an email to the email address you provided in the widget user form.</h4></li>
                    <li style={{ marginBottom: '10px' }}><h4>The call status gets shown below the button.</h4></li>
                    <li style={{ marginBottom: '10px' }}><h4>Consecutive calls to the webhook react differently in case a confirmation email has already been sent.</h4></li>
                    <li><h4>Select date and time to simulate a scheduled meeting</h4></li>
                </ol>
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label="Date&Time"
                    value={date}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            <Webhook ehToken={ehToken} ext_ref_id={ext_ref_id} date={date?.format().toString()} />

            <div style={{ width: '100%', height: 5, backgroundColor: '#b4b3b3', padding: 10 }} />

            <h3>TEST 2: Load the assessment widget</h3>
            <div style={{ textAlign: 'center' }}>
                <ol style={{ paddingLeft: '30px' }}>
                    <li style={{ marginBottom: '10px' }}><h4>If the process works, the assessment widget will be loaded below.</h4></li>
                    <li style={{ marginBottom: '10px' }}><h4>If a status for the assessment has already been set, the widget will not load again.</h4></li>
                    <li style={{ marginBottom: '10px' }}><h4>The widget is shown on the right side of the screen to test the MEDCASE page layout</h4></li>
                    <li><h4>The widget itself could show success/error messages in it.</h4></li>
                </ol>
            </div>

            <Button style={{ backgroundColor: '#ffffff', marginBottom: 20 }} onClick={_loadWidget}>
                {!loadWidget ? "Click to load the assessment widget" : "Close assessment widget"}
            </Button>

            {loadWidget ? <ResizableIframe
                src={`${window.location.origin}/assessment?token=${ehToken}&meetingId=test&meetingTimestamp=2023-02-21T14:00:00Z&ehRefId=${ext_ref_id}&clinicianFirstName=John&clinicianLastName=Doe`}
                srcOrigin={window.location.origin}
                containerStyle={{ width: '90%', marginTop: 20 }}
                iframeStyle={{ border: '1px', width: '100%', height: 700 }}
            /> : <></>}
        </div>
    );

}

export default DemoPage;