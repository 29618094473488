// import fetch from 'cross-fetch';
// import notifier from '../utils/notifier';

interface RESPONSE {
    success: boolean;
    token: string | null;
    message: string;
}

interface RESPONSE_WEBHOOK {
    success: boolean;
    message: string;
}


export const generateToken = async (partner: string, eh_access_key_id: string, ext_ref_id: string): Promise<RESPONSE> => {
    // Endpoint to validate the token
    const url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/embeddedWebappToken-createEmbeddedWebappToken`;

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ partner: partner, EH_ACCESS_KEY_ID: eh_access_key_id, ext_ref_id: ext_ref_id })
    }).then(response => response.json()).then(res => {

        if (!res.success) {
            // ERROR
            return {
                success: false,
                token: null,
                message: res.message
            };
        }

        // SUCCESS
        return {
            success: true,
            token: res.token,
            message: res.message
        };

    }).catch(err => {
        console.log('Error while generating the token ', err);
        return {
            success: false,
            token: null,
            message: err.message,
        }
    })
};

export const scheduleMeetingWebhookCall = async (token: string, data: Record<string, any>): Promise<RESPONSE_WEBHOOK> => {
    // Endpoint to send a H2H meeting confirmation
    const url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/dcSaveScheduledMeeting`;

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => response.json()).then(res => {

        if (res.status !== 200) {
            // ERROR
            return {
                success: false,
                message: res.message
            };
        }

        // SUCCESS
        return {
            success: true,
            message: `Success! Data Collection Meeting confirmation sent for id ${data.ehRefId}!`
        };

    }).catch(err => {
        console.log('Error while Scheduling a data collection meeting ', err);
        return {
            success: false,
            message: err.message
        }
    })
};