import React, { useState, useEffect, useRef } from 'react';

interface ResizableIframeProps {
    src: string;
    srcOrigin: string;
    containerStyle?: React.CSSProperties;
    iframeStyle?: React.CSSProperties;
}

export function ResizableIframe({ src, srcOrigin, containerStyle, iframeStyle }: ResizableIframeProps) {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [status, setStatus] = useState<string>("");

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            // iframe.addEventListener('load', resizeIframe);
            // window.addEventListener('resize', resizeIframe);
            window.addEventListener('message', function (event) {
                if (event.origin !== srcOrigin) return; // verify the origin of the message
                event.data && setStatus(JSON.stringify(event.data))
            });
        }
    }, []);

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, border: '1px solid black', ...containerStyle }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: 10 }}>
                    <h5>iFrame messages:</h5>
                    <div style={{ flex: 1 }}>{status}</div>
                </div>
            </div>
            <div style={{ flex: 1, border: '1px solid black', ...containerStyle }}>
                <iframe
                    ref={iframeRef}
                    src={src}
                    style={{ width: '100%', ...iframeStyle }}
                />
            </div>
        </div>

    );
}