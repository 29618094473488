import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { initFirebase, logoutFirebase, signInWithCustomToken } from "./config/firebase";
import firebase from "./config/firebase";
import { CircularProgress } from '@mui/material';
import TokenRoute from '../src/utils/routes/tokenRoute';
// import Payment from './containers/Payment';
import Assessment from './containers/Assessment';
import DemoPage from './containers/DemoPage';
import { initDefaultMessageService } from './utils/communicate';
import logo from './assets/logo.png';

const analytics = import("./config/analytics");

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <RoutesWithQueryParams />
      </BrowserRouter>
    </AuthProvider>
  );
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function RoutesWithQueryParams() {
  let query = useQuery();
  const isLoggedIn = firebase.auth().currentUser;

  return (
    <Routes>
      <Route path="/" element={<div>Invalid path</div>} />
      {/* <Route
        path="/payment"
        element={
          <TokenRoute
            path="/payment"
            isLoggedIn={isLoggedIn ? true : false}
            component={Payment} queryParams={query} />
        } />*/}
      <Route
        path="/assessment"
        element={
          <TokenRoute
            path="/assessment"
            isLoggedIn={isLoggedIn ? true : false}
            component={Assessment} queryParams={query} />
        }
      />
      <Route path="/validation" element={<TokenValidation />} /> {/*Here we will authenticate the Token*/}
      <Route path="/completed" element={<Completed />} />
      <Route path="/medcase" element={<DemoPage queryParams={query} />} /> {/* Demo page to test webhook and assessment widget */}
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<div>Invalid path</div>} /> {/*page-not-found route*/}
    </Routes>
  );
}

function Completed() {
  let auth = useAuth();
  const { state }: { state: any } = useLocation();
  const { ext_ref_id, description, status } = state;


  useEffect(() => {
    auth.signOut(() => {
      console.log('Session completed.')
      messagingService(ext_ref_id).sendMessage('end', { status, description });
    });
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img alt="" src={logo} height="100" />
      {'Thank you for providing the information.'}
    </div>
  );

}

function TokenValidation() {
  let auth = useAuth();
  let navigate = useNavigate();

  const { state }: { state: any } = useLocation();
  const { TOKEN, from, queryParams } = state;

  const IN_VALIDATION = 'in_process'
  const [validAuthToken, setValidAuthToken] = useState<boolean | string>(IN_VALIDATION);

  useEffect(() => {
    async function validationReq() {
      // TOKEN VALIDATION
      const { validation, customToken, ext_ref_id, partnerId } = await tokenValidation(TOKEN);

      if (!validation || !customToken) {
        setValidAuthToken(false);
        return
      }

      return auth.signIn(customToken, async (res: any) => {
        // Send user back to the page they tried to visit when they were
        // redirected to the validation. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the survey page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        if (res) {
          // Initialize the analytics using the proxy script
          (await analytics).attachProxyScript({
            partnerId,
            ext_ref_id,
          });
          ext_ref_id && messagingService(ext_ref_id).sendMessage('start', { message: `The ${from} page of the widget has loaded correctly.` });
          navigate(from, { replace: true, state: { queryParams, ext_ref_id, partnerId } });
          return
        }

        navigate('/error', { replace: true, state: { status: 'authfailed', ext_ref_id, message: 'Authentication failed. Please, try again later. If the error persists, contact the support team.' } });
        return
      });
    }

    validationReq();
  }, []);

  if (!validAuthToken && validAuthToken !== IN_VALIDATION) return <Navigate to={{ pathname: '/error' }} state={{ status: 'invalidtoken', ext_ref_id: null, message: 'The provided token has expired.' }} />

  return <Loading />
}

interface AuthContextType {
  user: any;
  signIn: (authToken: string, callback: Function) => void;
  signOut: (callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);

  let signIn = (authToken: string, callback: Function) => {
    // Initialize firebase first
    initFirebase();

    return signInWithCustomToken(authToken).then((res) => {
      if (res) {
        setUser(res);
      }

      callback(res);
    });
  };

  let signOut = (callback: VoidFunction) => {
    logoutFirebase();

    callback();
  };

  let value = { user, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

function Loading() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <img alt="" src={logo} height="100" />
      <CircularProgress />
    </div>
  );
}

function Error() {
  const { state }: { state: any } = useLocation();
  const message = state?.description ?? 'Please, provide a valid path.'
  const ext_ref_id = state?.ext_ref_id ?? null
  const status = state?.status ?? ''

  messagingService(ext_ref_id).sendError(status, message);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img alt="" src={logo} height="100" />
      {message}
    </div>
  );
}

interface TokenValidationInterface {
  validation: boolean;
  customToken: string | null;
  partnerId?: string;
  SSRmessage: string;
  ext_ref_id?: string;
}

async function tokenValidation(token: string): Promise<TokenValidationInterface> {
  // Endpoint to validate the token
  const url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/embeddedWebappToken-validateEmbeddedWebappToken`;

  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ token: token })
  }).then(response => response.json()).then(res => {

    if (!res.success) {
      // ERROR
      return {
        validation: false,
        customToken: null,
        SSRmessage: res.message,
        ext_ref_id: res.ext_ref_id,
      };
    }

    // SUCCESS
    return {
      validation: true,
      partnerId: res.partnerId,
      customToken: res.customToken,
      SSRmessage: res.message,
      ext_ref_id: res.ext_ref_id,
    };

  }).catch(err => {
    console.log('Error while fetching ', err);
    return {
      validation: false,
      customToken: null,
      SSRmessage: err.message,
    }
  })
};

const messagingService = (ext_ref_id: string) => initDefaultMessageService({
  ext_ref_id,
  allowSendingResult: true,
});